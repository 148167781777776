import React from "react";
import AdminPanel from "./AdminPanel";

function Admin() {
  return (
    <div>
      <AdminPanel />
    </div>
  );
}

export default Admin;
